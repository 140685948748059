import React, { Ref } from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label, PrimaryButton, IButtonStyles, TextField, IStyle, ITextFieldStyles, Spinner, SpinnerSize } from '@fluentui/react';
import ReCAPTCHA from "react-google-recaptcha";
import logo from './EM365Logo01.png';


type TRequestFor = "myself" | "household" | undefined;
type TRightToExercise = "accessMyInfo" | "deleteMyInfo" | "doNotSellMyInfo" | undefined;
type TReportOptions = "email" | "mail" | undefined;

const captchaSiteKey = '6LdVA3goAAAAAMhsXyluqcIIHM1YGVaQ_pD3z_e9'
const SubmissionUrl:string = String(process.env.REACT_APP_SubmissionUrl);

export interface IDoNotSellFormProps {

}

interface IDoNotSellFormState {
  recaptchaToken:string | null;
  requestFor: TRequestFor;
  rightToExercise: TRightToExercise;
  mailOptionShow:boolean;
  reportOption: TReportOptions;
  firstName:string | undefined;
  lastName:string | undefined;
  email:string | undefined;
  isSubmitting:boolean;
  submitStatus:"succeeded" | "failed" | undefined;
  submitError:string | undefined;
}

interface IDoNotSellPost{
  recaptchaToken:string | null;
  requestFor: TRequestFor;
  rightToExercise: TRightToExercise;
  reportOption: TReportOptions;
  firstName:string | undefined;
  lastName:string | undefined;
  email:string | undefined;
}

// const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const FormWrapperStyles: Partial<IStackStyles> = {
  root: {
    maxWidth: '480px',
    marginTop:20,
    marginLeft:'auto',
    marginRight:'auto',
    textAlign: 'center',
    color: '#605e5c',
    border: '1px solid #4c4c4d',
    borderRadius:7,
    overflow:'hidden'
  },
};

const buttonStyleNotChosen: Partial<IButtonStyles> = {
  root: {
    backgroundColor:'#6e9182',
    borderColor:'#6e9182',
    height:'auto',
    minHeight:42,
  },
  rootHovered:{
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
  },
  rootPressed:{
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
  }
};

const formTextFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width:'100%'
  },
};

const buttonStyleChosen: Partial<IButtonStyles> = {
  root: {
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
    height:'auto',
    minHeight:42,
  },
  rootHovered:{
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
  },
  rootPressed:{
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
  }
};

const submitButtonStyles: Partial<IButtonStyles> = {
  root: {
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
    marginBottom:20,
    width:95,
  },
  rootHovered:{
    backgroundColor:'#6e9182',
    borderColor:'#6e9182',
  },
  rootPressed:{
    backgroundColor:'#008e4f',
    borderColor:'#008e4f',
  }
};

export default class DoNotSellForm extends React.Component<IDoNotSellFormProps, IDoNotSellFormState> {

  private _captchaRef:any;

  public constructor(props: IDoNotSellFormProps) {
    super(props);

  this._captchaRef = React.createRef();

    this.state={
      recaptchaToken:null,
      requestFor:undefined,
      rightToExercise:undefined,
      mailOptionShow:false,
      reportOption:'email',
      firstName:undefined,
      lastName:undefined,
      email:undefined,
      isSubmitting:false,
      submitStatus:undefined,
      submitError:undefined,
    }

  } 

  public render(): React.ReactElement<IDoNotSellFormProps> {

    if(this.state.submitStatus && this.state.submitStatus === 'succeeded'){
      return(
        <Stack horizontalAlign="center" verticalAlign="center" styles={FormWrapperStyles} tokens={stackTokens}>
        <Stack style={{backgroundColor:'#4c4c4d',width:'100%'}} horizontalAlign='center' verticalAlign='center'><img style={{width:200}} alt="ECO Logo" src={logo}/></Stack>
          <Label styles={{root:{paddingBottom:20}}}>Your submission is received, thank you!</Label>
        </Stack>
      )
    }
    
    return (
      <Stack horizontalAlign="center" verticalAlign="center" styles={FormWrapperStyles} tokens={stackTokens}>
        <Stack style={{backgroundColor:'#4c4c4d',width:'100%'}} horizontalAlign='center' verticalAlign='center'><img style={{width:200}} alt="ECO Logo" src={logo}/></Stack>
        <Label>I would like to exercise my right to</Label>
        {/* <Stack horizontal tokens={stackTokens}>
          <PrimaryButton styles={this.state.requestFor && this.state.requestFor === 'myself' ? buttonStyleChosen : buttonStyleNotChosen} text='Myself' onClick={()=>this._onRequestForChange('myself')} />
          <PrimaryButton styles={this.state.requestFor && this.state.requestFor === 'household' ? buttonStyleChosen : buttonStyleNotChosen} text='My Household' onClick={()=>this._onRequestForChange('household')} />
        </Stack> */}
        <Stack styles={{root:{paddingLeft:25,paddingRight:25}}} tokens={stackTokens}>
        <Stack tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens}>
            <PrimaryButton 
              styles={this.state.rightToExercise && this.state.rightToExercise === 'accessMyInfo' ? buttonStyleChosen : buttonStyleNotChosen} 
              text='Access My Information' 
              onClick={()=>this._onRightToExcerciseChange('accessMyInfo')} 
            />
            <PrimaryButton 
              styles={this.state.rightToExercise && this.state.rightToExercise === 'deleteMyInfo' ? buttonStyleChosen : buttonStyleNotChosen} 
              text='Delete My Information' 
              onClick={()=>this._onRightToExcerciseChange('deleteMyInfo')} 
            />
            <PrimaryButton 
              styles={this.state.rightToExercise && this.state.rightToExercise === 'doNotSellMyInfo' ? buttonStyleChosen : buttonStyleNotChosen} 
              text='Do Not Sell My Information' 
              onClick={()=>this._onRightToExcerciseChange('doNotSellMyInfo')} 
            />
            
          </Stack>
          {this.state.rightToExercise === 'accessMyInfo' &&
          <>
            <Label style={{textAlign:'left'}}>Report Options:</Label>
            <Stack horizontal tokens={stackTokens}>
              <PrimaryButton styles={this.state.reportOption && this.state.reportOption === 'email' ? buttonStyleChosen : buttonStyleNotChosen} text='Email' onClick={()=>this._onReportOptionChange('email')} />
              <PrimaryButton styles={this.state.reportOption && this.state.reportOption === 'mail' ? buttonStyleChosen : buttonStyleNotChosen} text='Mail' onClick={()=>this._onReportOptionChange('mail')} />
            </Stack>
            </>
          }
          {this.state.reportOption && this.state.reportOption === 'mail' && this.state.rightToExercise && this.state.rightToExercise === 'accessMyInfo' &&
            <div style={{textAlign:'left'}}>
            <Label styles={{root:{color:'#707071'}}}>If you would like a mailed report, please send a letter with your name and return address to the following:</Label>
            <div style={{paddingLeft:15,paddingTop:10}}>
              <Text>
                Eco Material Technologies Inc Legal Department<br></br>
                10701 S River Front Pkwy Suite 300<br></br>
                South Jordan UT, 84095
              </Text>
            </div>
            </div>
          }
        </Stack>
        <Stack tokens={stackTokens} horizontalAlign='stretch' styles={{root:{textAlign:'left'}}}>
          
          <TextField disabled={this._isDisabled()} styles={formTextFieldStyles} label='First Name' onChange={(e,v)=>this.setState({firstName:v})} required />
          <TextField disabled={this._isDisabled()} styles={formTextFieldStyles} label='Last Name' onChange={(e,v)=>this.setState({lastName:v})} required />
          <TextField disabled={this._isDisabled()} styles={formTextFieldStyles} label='Email' onChange={(e,v)=>this.setState({email:v})} required  />
          <TextField disabled={true} value={this._getRightToExerciseDisplayValue()} styles={formTextFieldStyles} label='Request Details' multiline autoAdjustHeight />
        </Stack>
       

       <Text styles={{root:{padding:10}}}>By submitting this form, I confirm I am a resident of California, Colorado, Connecticut, Utah, or Virginia and that the information I have provided is accurate. </Text>
       <Stack.Item align='center'>
       <ReCAPTCHA
          ref={this._captchaRef}
          sitekey={captchaSiteKey}
          onChange={(token)=>this.setState({recaptchaToken:token})}
        />
       </Stack.Item>
       <Stack.Item align='center'>
          <PrimaryButton disabled={this._isSubmitDisabled()} styles={submitButtonStyles} text='Submit' onClick={()=>this._submitClick()} />
        </Stack.Item>
        <Stack.Item align='center' styles={{root:{paddingBottom:20}}}>
          <div>
          {this.state.submitStatus && this.state.submitStatus === 'failed' &&
            <div style={{color:'#e60000',fontWeight:600}}>{this.state.submitError}</div>
          }
          {this.state.isSubmitting &&
            <Spinner size={SpinnerSize.medium} />
          }
          </div>
        </Stack.Item>
        </Stack>
      </Stack>
    );
  }

  private _onRequestForChange (requestForValue:TRequestFor) {
    this.setState({requestFor:requestForValue})
  }

  private _onRightToExcerciseChange (rightToExerciseValue:TRightToExercise) {
    this.setState({rightToExercise:rightToExerciseValue})
  }

  private _onReportOptionChange (reportOptionValue:TReportOptions) {
    this.setState({reportOption:reportOptionValue})
  }

  private _isDisabled():boolean{
    if(!this.state.rightToExercise)
    {
      return true;
    }    
    if(this.state.reportOption && this.state.reportOption === 'mail'){
      return true;
    }
    return false;
  }

  private _isSubmitDisabled():boolean{
    if(!this.state.recaptchaToken){
      return true;
    }
    if(!this.state.rightToExercise)
    {
      return true;
    }    
    if(this.state.reportOption && this.state.reportOption === 'mail'){
      return true;
    }
    if(!this.state.firstName || !this.state.lastName || !this.state.email){
      return true;
    }
    return false;
  }

  private _getRightToExerciseDisplayValue():string{
    if(this.state.rightToExercise){
      if(this.state.rightToExercise === 'accessMyInfo'){
        return "I would like to exercise my right to Access my Information.  I would like to recieve a report.";
      }
      if(this.state.rightToExercise === 'deleteMyInfo'){
        return "I would like to exercise my right to request my personal information be deleted.";
      }
      if(this.state.rightToExercise === 'doNotSellMyInfo'){
        return "I would like to exercise my right to request my personal information not be sold.";
      }
    }
    return "";
  }

  private async _submitClick():Promise<void>{
    this.setState({isSubmitting:true});
    const postData:IDoNotSellPost = {
      recaptchaToken:this.state.recaptchaToken,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      email:this.state.email,
      reportOption:this.state.reportOption,
      requestFor:this.state.requestFor,
      rightToExercise:this.state.rightToExercise
    }
    const url = SubmissionUrl;
    try{
      const response:Response = await fetch(url,{
        method:'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body:JSON.stringify(postData)
      }
        )
        if(response && response.status === 200){
          this.setState({isSubmitting:false,submitError:undefined,submitStatus:'succeeded'});
        }
        else{
          this.setState({isSubmitting:false,submitError:"reCaptcha error.  Please try again.",submitStatus:'failed'});
        }

    }
    catch(ex){
      this.setState({isSubmitting:false,submitError:"Unknown error.  Please try again in a few minutes",submitStatus:'failed'});
    }
    
  }

}


